<template>
  <div class="auto-wrap">
    <search-auto v-if="getSearchCondition"></search-auto>
    <div class="discount-list-wrap">
      <div class="bar-wrap">
        <el-button
          class="create-button"
          type="primary"
          @click="openDialog('autoDiscountSetting', { action: 'create' })"
        >
          新增
        </el-button>
      </div>
      <el-table
        ref="listTable"
        :data="getAutoDiscountList"
        header-cell-class-name="cus-table-header"
        row-class-name="cus-table-row"
        empty-text="尚無資料"
        class="table-basic"
        height="100%"
        @expand-change="handleRowClick"
      >
        <el-table-column type="expand">
          <template #default="props">
            <div class="remark">
              <p>活動說明：{{ props.row.remark }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="event_id" label="活動ID" width="110" />
        <el-table-column prop="name" label="活動名稱" :show-overflow-tooltip="true" />
        <el-table-column prop="available_shop_type" label="門市" width="220">
          <template #default="scope">
            <span v-if="scope.row.available_shop_type === 1">全門市</span>
            <span v-else-if="scope.row.available_shop_type === 2">
              {{ getAreaText(scope.row.available_shop_value_array) }}
            </span>
            <span
              v-else-if="scope.row.available_shop_type === 3"
              class="download-text"
              @click="downloadFile(scope.row.available_shop_file_url)"
            >
              指定門市（csv）
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="available_start_time" label="活動日期" width="160">
          <template #default="scope">
            <span v-if="scope.row.time_period_type === 1">不指定時間</span>
            <span v-if="scope.row.time_period_type === 2">
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.available_start_time) }}~
              <br />
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.available_end_time) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="available_status" label="狀態" width="100" />
        <el-table-column label="功能" fixed="right" align="center" width="130">
          <template #default="scope">
            <el-dropdown class="action">
              <span class="el-dropdown-link">
                操作
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="openDialog('autoDiscountSetting', { action: 'edit', init: scope.row, index: scope.$index })"
                  >
                    編輯
                  </el-dropdown-item>
                  <el-dropdown-item @click="handleDeleteDiscount(scope.row)">刪除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        v-model:currentPage="currentPage"
        :page-size="getPageSize"
        layout="prev, pager, next, jumper"
        :total="getPageTotal"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { ref, getCurrentInstance, defineAsyncComponent, watch } from 'vue';
import { getAreaText } from '@/utils/define';
import { downloadFile } from '@/utils/download';
import { formatDateTime, getFormatDate } from '@/utils/datetime';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { useDialog } from '@/utils/dialog';

export default {
  name: 'auto-discount',
  components: {
    AutoDialog: defineAsyncComponent(() => import('@/components/discount/AutoDialog.vue')),
    SearchAuto: defineAsyncComponent(() => import('@/components/discount/SearchAuto.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { dialogTemplate, openDialog, closeDialog } = useDialog();
    const route = useRoute();
    const router = useRouter();

    //取得資料
    const { getAutoDiscountList, getPageSize, getPageTotal, getSearchCondition } = useGetters('autoDiscount', [
      'getAutoDiscountList',
      'getPageSize',
      'getPageTotal',
      'getSearchCondition',
    ]);
    const SET_SEARCH_CONDITION = useMutations('autoDiscount', ['SET_SEARCH_CONDITION']);
    const { doGetAutoDiscountList, doGetAutoDiscountSummary } = useActions('autoDiscount', [
      'doGetAutoDiscountList',
      'doGetAutoDiscountSummary',
    ]);

    //取得店家清單/商品類別清單/商品樹/商品列表/容量選項對照表
    const doGetProductInfo = useActions('autoDiscount', ['doGetProductInfo']);
    const { doGetOptionList, doGetStoreList } = useActions('common', ['doGetOptionList', 'doGetStoreList']);
    const init = (() => {
      doGetProductInfo();
      doGetOptionList();
      doGetStoreList();
    })();

    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: 1 };
    const searchDataInit = {
      date: [getFormatDate(), getFormatDate()],
      event_id: '',
      name: '',
      available_status: '',
      shop_id: '',
    };
    router.push({ query, replace: true }).then(() => {
      SET_SEARCH_CONDITION(searchDataInit);
      refreshList({ action: 'init' });
    });

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };

    //更新list
    watch(
      () => route.query.page,
      () => {
        if (route.query.page && getSearchCondition.value) {
          currentPage.value = Number(route.query.page);
          refreshList({ action: 'page' });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );
    const refreshList = async (data) => {
      if (data.action === 'create') {
        //若為新增優惠時，重設搜尋條件
        if (data.event.available_start_time) {
          let searchData = JSON.parse(JSON.stringify(searchDataInit));
          searchData.date = [
            data.event.available_start_time.slice(0, 10),
            data.event.available_start_time.slice(0, 10),
          ];
          SET_SEARCH_CONDITION(searchData);
        } else {
          SET_SEARCH_CONDITION(searchDataInit);
        }
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
      } else if (data.action === 'edit') {
        if (data.event.available_start_time) {
          let searchData = JSON.parse(JSON.stringify(searchDataInit));
          searchData.date = [
            data.event.available_start_time.slice(0, 10),
            data.event.available_start_time.slice(0, 10),
          ];
          SET_SEARCH_CONDITION(searchData);
        }
      }
      //排除分頁不打summary api
      if (data.action !== 'page') await doGetAutoDiscountSummary(getSearchCondition.value);
      //summary會獲得新total，需計算頁碼，因刪除時會有減頁的情況
      if (currentPage.value > Math.ceil(getPageTotal.value / getPageSize.value))
        currentPage.value = Math.max(Math.ceil(getPageTotal.value / getPageSize.value), 1);
      //頁碼確認後才打list api
      await doGetAutoDiscountList({
        search: getSearchCondition.value,
        start: (currentPage.value - 1) * getPageSize.value,
      });
      closeDialog();
    };

    //刪除優惠
    const doDeleteAutoDiscount = useActions('autoDiscount', ['doDeleteAutoDiscount']);
    const handleDeleteDiscount = (item) => {
      proxy
        .$confirm(`確定要刪除－${item.name}？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          await doDeleteAutoDiscount(item.event_id);
          refreshList({ action: 'delete' });
          proxy.$message({
            type: 'success',
            message: '刪除成功',
          });
        });
    };

    //展開收合row
    const handleRowClick = (row, expandedRows) => {
      if (expandedRows.includes(row)) {
        expandedRows.forEach((e) => {
          if (e !== row) listTable.value.toggleRowExpansion(e, false);
        });
      }
    };

    //離開清空vuex
    const RESET = useMutations('autoDiscount', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/discount/auto')) RESET();
      }
    );
    return {
      downloadFile,
      getAreaText,
      formatDateTime,
      dialogTemplate,
      openDialog,
      getSearchCondition,
      getAutoDiscountList,
      getPageSize,
      getPageTotal,
      init,
      listTable,
      currentPage,
      handleCurrentChange,
      refreshList,
      handleDeleteDiscount,
      handleRowClick,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$searchbar-height: 70px;
$pagination-height: 50px;
.auto-wrap {
  height: 100%;
  @include basic-search-condition;
}
.discount-list-wrap {
  width: 100%;
  height: 100%;
  padding: $searchbar-height $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}

.bar-wrap {
  position: absolute;
  display: flex;
  top: calc($searchbar-height / 2);
  right: $padding-unit;
  transform: translateY(-50%);
}
.create-button {
  width: 110px;
  margin-right: 12px;
}
.table-basic {
  width: 100%;
  border-top: 1px solid $el-border-color-lighter;
}
.action {
  cursor: pointer;
  width: 110px;
  @include flex-center-center;
}
.el-dropdown-link {
  color: $color-primary;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
.remark {
  padding: 10px 60px;
  color: $color-text-light;
  font-size: 1.5rem;
}
</style>
